.card {
  width: 250px;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 1px #bbb;
  border-radius: 5px;
  margin-top: 50px;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.content span:first-child {
  font-size: 14px;
  color: #65748b;
  margin-bottom: 15px;
}

.content span:last-child {
  font-size: 19px;
  margin-left: 5px;
}
