.container {
  width: 100vw;
  min-height: calc(100vh - 65px);
  display: flex;
}

.content {  
  flex: 1.5;  
  padding-left: 15px;
}

.timeDiv {    
  padding: 0 10px;    
  flex: 1;
  display: flex;
  flex-direction: column;  
  align-items: center;  
  justify-content: center;  
  background-color: #0a3050;    
  color: #fff;
  text-shadow: 1px 1px 0.5px #222;  
  text-align: center;
}

.timeDiv h1 {
  font-size: 50px;
}

.time {      
  width: 200px;
  display: flex;  
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}

.time .title {
  font-size: 25px;  
  margin-top: 10px;
}

.time .box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  margin-right: 3px;
  color: #222;
  font-size: 19px;
}

.time p {
  margin-top: 10px;  
}

.time .date {
  font-size: 25px;
}

.yearBtnGroup {        
  display: flex;  
  flex-wrap: wrap;
  justify-content: flex-start;  
  width: 100%;         
  font-size: 50px;
}

.yearBtnGroup button {   
  margin: 8px;   
  font-size: 18px; 
  width: 120px;  
  height: 45px;
}

.yearBtnGroup .active {
  background-color: #2b84dc;
  color: #fff;
}

.yearBtnGroup .active:hover {
  background-color: #2b84dc;
  color: #fff;
}


@media (max-width: 600px) {
  .container {
    width: 100vw;
    min-height: calc(100vh - 65px);
    display: flex;
    flex-direction: column-reverse;
  }
  
  .content {
    flex: 50;  
  }
  
  .timeDiv {
    padding: 20px 0;  
    flex: 1;
    display: flex;
    flex-direction: column;  
    align-items: center;          
    text-shadow: 1px 1px 1px #222;    
  }

  .timeDiv img {
    width: 150px;
  }
  
  .time {
    text-align: center;
    margin-top: 10px;
  }
  
  .time p {
    margin-top: 10px;
  }
  
  .time .date {
    font-size: 25px;
  }
}