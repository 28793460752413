* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;   
  font-family: 'Roboto', sans-serif;  
}

body {
  cursor: default;
  background-color: #F9FAFC;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}