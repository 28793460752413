.fullScreenBtn {
  outline: none;
  border: none;
  background-color: #00000000;    
  position: absolute;  
  right: 15px;
  bottom: 5px;
  cursor: pointer;  
}

.fullScreenBtn svg {  
  font-size: 60px;
}