.lastColumn {
    width: 380px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    overflow: hidden;
}

.lastColumn .content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    /* padding-right: 16px;
    padding-left: 16px; */
    background-color: #EBECF0;
    border-radius: 8px;
}

.lastColumn .content .btnWrapper {
    padding: 16px;
    color: #6a6a6a;
    width: 100%;
}

.lastColumn .content .btnWrapper .addButton {
    font-size: 1rem;
    padding: 0;
    justify-content: flex-start;
    border-radius: 8px;
    text-transform: none;
    color: #6a6a6a;
}

.lastColumn .content .btnWrapper .addButton:hover {
    background-color: transparent;
}