.container {
  width: 100%;
  min-height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
}

.banner {
  width: 100%;
  max-width: 1100px;
  height: 250px;
  background-color: #5048e5;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.content {
  flex: 1;
  height: 100%;
  padding-left: 40px;
  color: #fff;
}

.content h1 {
  margin-top: 50px;
  margin-bottom: 10px;
  text-shadow: 1px 1px 1px #555;
}

.content p {
  margin-bottom: 40px;
  text-shadow: 1px 1px 1px #444;
}

.btn {
  width: 180px;
  height: 45px;
  letter-spacing: 0.4px;
  color: #222;
  background-color: #10b981;
  border-radius: 10px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn:hover {
  color: #111;
  background-color: #0ca974;
}

.btn span {
  margin-top: 2px;
}

.menuIcon svg {
  font-size: 15px;
  margin-bottom: -3px;
}


@media (max-width: 840px) {
  .container {
    width: 100%;
    min-height: calc(100vh - 65px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 10px;
  }

  .banner {
    width: 100%;
    max-width: 1100px;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 40px 0;
  }
}