.navigation {
    padding: 20px 0 10px 0;
}

.navigation .btnBack {
    padding: 0;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}