.container {
  width: 100%;
  min-height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
}

.cards {
  margin-top: 30px;
  width: 100%;
  max-width: 1100px;
  display: flex;  
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}