.loginPage {
  width: 100%;
  height: 100vh;  
  background-color: #051726;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginForm {
  background-color: #fff;
  width: 550px;    
  height: 450px;
  border-radius: 15px;
  box-shadow: 7px 10px 10px #000;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginForm img {  
  width: 200px;
  margin-bottom: 60px;
}

@media (max-width: 600px) {
  .loginForm {    
    width: 95%;    
    height: 400px;
    border-radius: 15px;
    box-shadow: 7px 10px 10px #000;
    padding: 0 15px;    
  }

  .loginForm img {  
    width: 180px;
    margin-bottom: 40px;
  }
}

.loginForm .input {
  margin-bottom: 15px;  
}

.loginForm .btnEntrar {
  margin-top: 40px;
  height: 45px;
  font-size: 17px;
}

.resetPassword {
  margin-top: 30px;
  color: #10568f;
  text-decoration: underline;
}

.resetPassword:hover {
  color: #0a3050;
  text-decoration: none;
}