.modal {
    display: flex;
    flex-direction: column;
    width: calc(100% - 64px);
    max-height: calc(100% - 64px);
    max-width: 1000px;
    border-radius: 8px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 24%) 0px 25px 50px;
    background-image: none;
    margin: 32px;
    position: relative;
    overflow-y: auto;
}

/*.modal .checkListInput {
    borderColor: "transparent",
    borderRadius: 2,
    "& .MuiInputBase-input": {
    padding: "8px 16px"
    },
    
    "& .MuiInputBase-input:focus": {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 2
    }
}

.modal .checkListInput :hover {
    textDecoration: "none",
    backgroundColor: "#ccccd3",
    transition: [
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    ]
}*/