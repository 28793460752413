.board {
    display: flex;
    flex-grow: 1;
    flex-Shrink: 1;
    overflow: auto hidden;
}

.board .columnsWrapper {
    display: flex;
    padding-top: 24px;
    padding-bottom: 24px;
}
