.drawer *::-webkit-scrollbar {
  width: 0;
}

.sidebar * {
  color: #fff;
}

.sidebar {
  width: 100vw;
  min-height: 100%;
  background-color: #2d2d2d;
  padding: 0 5px 50px 30px;
  margin-bottom: -1000000%;
}

.logoBox,
.logoBoxWhite {
  width: 200px;
  border-radius: 7px;
  padding: 5px 10px;
  margin: 30px auto 20px auto;
}

.logoBoxWhite {
  background-color: #ffffff;
  box-shadow: 1px 1px 4px #000000;
}

.listTitle {
  padding-top: 25px;
  padding-left: 10px;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #888;
  letter-spacing: 0.8px;
  font-weight: bold;
}

.listItem {
  border: 1px solid #222;
  box-shadow: 2px 2px 2.5px #111;
  border-radius: 5px;
  margin: 5px;
  width: 100%;
  min-height: 70px;
  background-color: #262626;
}

.listItem * {
  font-size: 19px !important;
}

.listItemBtn {
  display: flex;
  flex-direction: column;
}

.listItemText {
  margin-left: 7px;
  margin-top: 7px;
}

.listItemText * {
  font-size: 19px !important;
}
