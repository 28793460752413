.column {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    overflow: hidden;
    width: 380px;
}

.column .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 0 16px;
    background-color: #EBECF0;
    border-radius: 8px 8px 0 0;
}