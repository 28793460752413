.drawer *::-webkit-scrollbar {
  width: 0;
}

.sidebar * {
  color: #fff;
}

.sidebar {
  width: 280px;
  min-height: 100%;
  background-color: #262626;
  padding-left: 10px;
  padding-right: 20px;
  margin-bottom: -1000000%;
  padding-bottom: 50px;
}

.listItem {
  margin-top: 0.5px;
}

.listItem2 {
  padding-left: 50px;
}

.listItem3 {
  padding-left: 5px;
}

.listItemText {
  margin-left: -15px;
}

.listTitle {
  padding-top: 25px;
  padding-left: 10px;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #888;
  letter-spacing: 0.8px;
  font-weight: bold;
}

.logoBox,
.logoBoxWhite {
  width: 170px;
  border-radius: 7px;
  padding: 5px 10px;
  margin: 30px auto 20px auto;
}

.logoBoxWhite {
  background-color: #ffffff;
  box-shadow: 1px 1px 4px #000000;
}

@media (max-width: 600px) {
  .listItemText * {
    font-size: 19px !important;
  }
  
  .smallList {
    display: flex;
    flex-wrap: wrap;
  }

  .smallList2 {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid red;
  }

  .smallList li {
    border: 1px solid #222;
    box-shadow: 2px 2px 2.5px #111;
    border-radius: 5px;
    margin: 5px;
    width: 165px;
    height: 90px;
    background-color: #262626;
  }

  .smallList .listItemBtn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 10px;
  }

  .listItem {
    margin-top: 0.5px;
  }

  .listItem2 {
    padding-left: 0px;
  }

  .listItem2SmallList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .listItem2SmallListBtn {
    border: 1px solid #222;
    box-shadow: 2px 2px 2.5px #111;
    border-radius: 5px;
    margin: 5px;
    width: 165px;
    height: 90px;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #262626;
  }

  .listItem3SmallListBtn {
    border: 1px solid #222;
    box-shadow: 2px 2px 2.5px #111;
    border-radius: 5px;
    margin: 5px;
    width: 100%;
    background-color: #262626;
  }

  .listItem3 {
    padding-left: 5px;
  }

  .listItemText {
    margin-left: 5px;
    margin-top: 7px;
  }

  .listItemText2 {
    margin: 0;
    margin-top: 3px;
  }

  .listTitle {
    padding-top: 25px;
    padding-left: 10px;
    padding-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-weight: bold;
  }

  .logoBox,
  .logoBoxWhite {
    width: 170px;
    border-radius: 7px;
    padding: 5px 10px;
    margin: 30px auto 20px auto;
  }

  .logoBoxWhite {
    box-shadow: 1px 1px 4px #000000;
  }
}
